import React, { useEffect, useRef, useState } from 'react';
import ActionBar from '../../../components/partLibrary/ActionBar';
import FolderComponent from '../../../components/partLibrary/FolderComponent';
import ToolbarComponent from '../../../components/partLibrary/ToolbarComponent';
import FileComponent from '../../../components/partLibrary/FileComponent';

import { getFolders, getFolder, getFiles, getFileSvg, createFile, createFolder, getFileDxf, updateFolder, updateFile } from '../../../crud/partLibrary.crud';
import {useParams, useHistory, withRouter} from 'react-router-dom';
import { convertDxfSimple } from "../../../crud/draw.crud";
import initApi from "../../../crud/apiConfig/apiConfig";
import { API_URL, API_URLS } from "../../../common/constants/api/apiConstants";
import { priceCalculator } from "../../../partials/content/priceCalculator";

import {drawActions} from "../../../store/ducks/draw.duck";
import {FormattedMessage, injectIntl} from "react-intl";
import {connect} from "react-redux";
import {ORDER_TYPE_LIB, ORDER_TYPE_SIMPLE, RESAVE} from "../../../common/constants/cadJSON";
import {compress} from "lz-string";
import makerjs from "makerjs";
import {isArray} from "lodash";
import {Skeleton} from "@mui/material";
import OverviewPart from "../mypartlibrary/basketTable";
import {Button as BootstrapButton, Modal} from "react-bootstrap";
import axios from 'axios';
import { toast } from "react-toastify";


let addBtn, cart, counter, dxfw;

const position = "fixed";



function init(e, fileId) {
    cart = document.querySelector("#cart div");
    counter = document.querySelector("#bcount");
    dxfw = document.querySelector(`#svg-${fileId}`);
    addItem(e)
}

function addItem(e) {

    const fromElement = dxfw

    let btnY =
        position === "fixed"
            ? fromElement.getBoundingClientRect().top
            : fromElement.offsetTop,
        btnX =
            position === "fixed"
                ? fromElement.getBoundingClientRect().left
                : fromElement.offsetLeft,
        flyingBtn = dxfw.cloneNode(true);


    cart.classList.remove("addedCount");
    counter.classList.remove("addedCount");

    flyingBtn.classList.add("flyingBtn");
    flyingBtn.style.position = position;
    flyingBtn.style.top = `${btnY}px`;
    flyingBtn.style.left = `${btnX}px`;
    flyingBtn.style.opacity = "0.8";

    const speed = 2000;
    const curveDelay = 3500;

    flyingBtn.style.transition = `all ${speed / 1000}s ease, right ${(speed + curveDelay) / 1000}s ease, transform ${speed / 2000}s ease ${curveDelay / 2000}s`;

    document.body.appendChild(flyingBtn);

    flyingBtn.style.top = `${counter.offsetTop + counter.offsetHeight + 60}px`;
    flyingBtn.style.right = `${counter.offsetRight + counter.offsetWidth + 200}px`;

    flyingBtn.style.height = "5rem";
    flyingBtn.style.width = "10rem";
    flyingBtn.style.transform = "scale(0)";

    setTimeout(() => {
        flyingBtn.remove();
        storeItems();
    }, speed * 1.5);
}

function storeItems() {
    let itmsInCart = cart.getAttribute("data-count");
    cart.classList.add("addedCount");
    counter.classList.add("addedCount");

    if (!itmsInCart) {
        cart.setAttribute("data-count", 1);
    } else {
        cart.setAttribute("data-count", parseInt(itmsInCart, 10) + 1);
    }
}


 function PartLibrary({...props}) {
    const { folderId } = useParams();
    const history = useHistory();

     const [files, setFiles] = useState([]);
     const [parsedData, setParsedData] = useState([]);
     const [availableParts, setAvailableParts] = useState([]);
     const [isLoading, setIsLoading] = useState(false);


    const [foldersList, setFoldersList] = useState([]);
    const [currentFolder, setCurrentFolder] = useState();
    const [prevFolder, setPrevFolder] = useState();
    const [filesList, setFilesList] = useState([]);
    const [check, setCheck] = useState(false);
    const [actionBarState, setActionBar] = useState({
        checkbox: { checked: false, indeterminate: false },
        basket: [],
        basket_fileContest: {},
        selectedFiles: [],
        specified: 0
    });

    const [modalState, setModal] = useState({
        show: false,
        body: <span>Table</span>,
        headerTitle: <span>Modal</span>,
        onCancel: () => {},
        submitSection: <span></span>,
        defaultMaterial: null,
        choiceData: [],
    });

    const uploadRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState('');
    const [progress, setProgress] = useState(0);
    const [fileContents, setFileContents] = useState([]);
    const [basketFileContents, setBasketFileContents] = useState([]);
     const [pdfFileContents, setPdfFileContents] = useState([]);
     const [pdfFiles, setPdfFiles] = useState([]);
     const [parsedAiData, setParsedAiData] = useState([]);

    const [checkedState, setCheckedState] = useState(
        filesList.reduce((accum, file) => ({ ...accum, [file.id]: false }), {})
    );


     const [openModalConfigurator, setOpenModalConfigurator] = useState(false);

    const [searchTerm, setSearchTerm] = useState('');

     const concatWithPartLibrary = async (data) => {
         const results = await Promise.all(
             data.items.map(async (item) => {

                 const possibleTitles = [item.material, item.unit, item.part, item.description].filter(Boolean);

                 const responses = await Promise.all(possibleTitles.map(async (title) => {
                     const response = await initApi().get(`/api/file/filter?name=${title}`);
                     return response.data;
                 }));

                 setParsedAiData(prevState => [...prevState, ...responses]);

                 if (responses.length) {
                     console.log(responses, 'responses responses ')
                     const responseObj = responses[2];
                     responseObj['pricePerUnit'] = item.pricePerUnit;

                     //  console.log(responseObj, 'responseObj')
                     //  setParsedAiData(responseObj);
                     //  fetchSVGs(responseObj, true);

                     return responseObj

                 }

                 //  return {
                 //      "id": null,
                 //      "name": "jaaaa",
                 //      "svgPath": "",
                 //      "pngPath": null,
                 //      "stepPath": null,
                 //      "dxfPath": "",
                 //      "cwPath": null,
                 //      "createdAt": "",
                 //      "updatedAt": "",
                 //      "svgWidth": null,
                 //      "svgHeight": null,
                 //      "minPrice": null,
                 //      "svgContent": null,
                 //      "totalArea": null,
                 //      "totalLength": null,
                 //      "pricePerUnit": item.pricePerUnit
                 //  };
             }
             )
         );


         console.log(results, 'all results')
         const filteredResults = results.flat().filter(result => result !== null && Object.keys(result).length > 0);

         setParsedAiData(filteredResults);
         fetchSVGs(filteredResults, true);
         console.log(filteredResults, 'filteredResults')
     };



     const handleDrop = (acceptedFiles) => {
         const filteredFiles = acceptedFiles.filter(file => file.type === 'application/pdf');

         if (filteredFiles.length > 0) {
             const formData = new FormData();
             formData.append('file', filteredFiles[0]);

             setIsLoading(true);
             axios.post('https://api.testing.cutwise.nl/upload', formData)
                 .then(response => {
                     setFiles([...files, ...filteredFiles]);
                     concatWithPartLibrary(response.data)
                     setPdfFiles(response.data)
                     console.log(response, 'ai data response')
                 })
                 .catch(error => {
                     toast.error("Order items not found in pdf file, check your pdf or connect to support", {
                         position: 'top-right',
                         autoClose: 4500,
                         hideProgressBar: true,
                         closeOnClick: true,
                         pauseOnHover: true,
                         draggable: false,
                         progress: undefined,
                     });
                 })
                 .finally(() => {
                     setIsLoading(false); 
                 });
         } else {
             console.error('Only PDF files are allowed.');
         }
     };

    useEffect(() => {
        setSearchTerm(searchTerm);
    }, [searchTerm, setSearchTerm]);


    useEffect(()=> {
        // init();

        props.materialDataRequest();
     },[]);
    
    useEffect(() => {

        const initialize = async () => {
            await fetchMaterials();

            if (folderId === 'main') {
                await fetchFolders();
            }
            else {
                await fetchFolder(folderId);
            }

        };

        initialize();
    }, [folderId, openModalConfigurator]);

    useEffect(() => {
        const {  updateNewFile, updateDrawSVGarray, draw } = props;
        const { newFile } = draw;

        if (Object.keys(newFile).length > 0) {
           switch (newFile.type) {
             case ORDER_TYPE_LIB:
               updateNewFile({});
               break;
             case ORDER_TYPE_SIMPLE:
               history.push(`/checkout/${newFile.project_id}`);
               
               updateNewFile({});
               updateDrawSVGarray([]);
               break;
             case RESAVE:
               updateNewFile({});
               break;
             default:
               break;
           }
         }
    }, [JSON.stringify(props.draw.newFile)]);

    const materialById = (choiceData) => {
        const byOBJ = {};
        Object.values(choiceData).forEach((M) =>
            M.choices.forEach(
                (choice) =>
                    (byOBJ[choice.id] = {   
                        ...choice,
                        name: M.name,
                        color_nl: choice.color,
                    })
            )
        );
        return byOBJ;
    };

    const adoptMaterial = (choiceData, materialData) => {
        const adoptedGlobData = {};
        materialData.forEach(
            (item) =>
                (adoptedGlobData[item.id] = {
                    price: Number(item.price),
                    cutting_speed: item.cutting_speed,
                })
        );

        const adopted = {};

        choiceData &&
        choiceData.materials.forEach(
            (item) =>
                (adopted[item.name] = {
                    ...item,
                    choices: item.choices.map((choice) => ({
                        ...choice,
                        key: choice.id,
                        price: adoptedGlobData[choice.id].price,
                        cutting_speed: adoptedGlobData[choice.id].cutting_speed,
                        value: `${choice.thickness} ${choice.id}`,
                        label: (
                            <div style={{ display: 'flex' }}>
                                <span
                                    dangerouslySetInnerHTML={{ __html: choice.icon }}
                                    style={{ marginLeft: '5px' }}
                                ></span>
                                <span>{choice.thickness + ` mm ${choice.color}`}</span>
                            </div>
                        ),
                    })),
                })
        );

        return adopted;
    };


    const handleSpecifiedParts = (file) => {
     
        if(file.material)
            {
                let count = actionBarState.specified  + 1
                setActionBar(prevState => ({
                    ...prevState,
                    specified: count
                }));
            }
    }

    const fetchMaterials = async () => {
        try {
            const { data: globDataResponse } = await initApi().get(
                API_URL + API_URLS.GET_MATERIALS_DATA
            );
            const { data: choicesData } = await initApi().get(
                API_URL + API_URLS.MATERIAL_DATA
            );

            const choiceData = adoptMaterial(choicesData, globDataResponse);

            setModal((prevState) => ({
                ...prevState,
                defaultMaterial: 1,
                choiceData,
            }));
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

     const createCheckout = async function(files) {

         const { createProject } = props;

         const ARRAY_DXF = await Promise.all(files.map( async file => {
             const { id, name, material: materialObject } = file;
             const getDxf = await getFileDxf(id)
             const  DXF_TEXT = getDxf.data
             const quantity = file.quantity


             return { DXF_TEXT, name, materialObject, quantity}
         }));

        createProject(ARRAY_DXF, ORDER_TYPE_SIMPLE, 'dxf', ARRAY_DXF.quantity);

     }

     const fetchSVGs = async (fileList, pdfParser) => {
        const svgPromises = fileList.map(async (item, index) => {
            if (item.id) {
                const { data } = await getFileSvg(item.id);
                const fillColor =
                    item?.material !== null ? '#c0c0c0' : 'rgb(243, 179, 179)';

                const parser = new DOMParser();
                const svgDoc = parser.parseFromString(data, 'image/svg+xml');
                const svgElement = svgDoc.querySelector('svg');
                const width = svgElement?.getAttribute('width');

                const height = svgElement?.getAttribute('height');
                const viewBox = svgElement?.getAttribute('viewBox').split(' ');
                const paths = svgDoc.querySelectorAll('path');
                const polyLines = svgDoc.querySelectorAll('polyline');
                const circles = svgDoc.querySelectorAll('circle');
                const modelsMaker = [];
                const pathsMaker = [];


                let totalLength = 0;
                let totalArea = viewBox && viewBox.length > 3 && (viewBox[2] - 10) * (viewBox[3] - 10);
               
                paths.forEach((path) => {
                    totalLength += path.getTotalLength();
                });

                paths.forEach((elem, index) => {
                    let pathData = elem.getAttribute('d');
                try {
                    var model = makerjs.importer.fromSVGPathData(pathData);
                    model = makerjs.model.mirror(model, false, true);

                    if (model && model.paths) {
                        Object.keys(model.paths).forEach((key) => {
                            if (model.paths[key] && fillColor) {
                                model.paths[key].fill = model.paths[key].fill || fillColor;
                            }
                        });
                    }

                    if (model && model.models) {
                        Object.keys(model.models).forEach((key) => {
                            let shape = model.models && model.models[key];

                            if (shape && shape.paths && fillColor) {
                                shape.paths.fill = shape.paths.fill || fillColor;
                            }
                        });
                    }

                    modelsMaker['path' + index] = model;
                } catch (e) {
                    console.error(e);
                }
            });

                polyLines.forEach((polyline) => {
                    totalLength += polyline.getTotalLength();
                });

                circles.forEach((circle) => {
                    const radius = parseFloat(circle.getAttribute('r'));

                    totalLength += 2 * Math.PI * radius;
                });

                circles.forEach((elem, index) => {
                    let cx = parseFloat(elem.getAttribute('cx'));
                    let cy = parseFloat(elem.getAttribute('cy'));
                    let r = parseFloat(elem.getAttribute('r'));

                    try {
                        let circle = new makerjs.paths.Circle([cx, cy], r);
                        circle.fill = '#F2F3F8';

                        pathsMaker['path' + index] = circle;
                    } catch (e) {
                        console.error(e);
                    }
                });

                let modelWrapper = {
                    models: modelsMaker,
                    paths: pathsMaker,
                };

                const svgString = makerjs.exporter.toSVG(modelWrapper, {
                    useSvgPathOnly: true,
                    layerOptions: { fill: true },
                });

                const doc = parser.parseFromString(svgString, 'image/svg+xml');
                const docSvgEl = doc.documentElement;

                if (docSvgEl.hasAttribute('viewBox')) {
                    const viewBoxValues = docSvgEl.getAttribute('viewBox').split(' ');
                    const newViewBox = `-2 -3 ${parseFloat(viewBoxValues[2]) + 4} ${parseFloat(viewBoxValues[3]) + 4}`;
                    docSvgEl.setAttribute('viewBox', newViewBox);
                } else {
                    console.error('The SVG element does not have a viewBox attribute.');
                }

                docSvgEl.setAttribute('width', '50')
                docSvgEl.setAttribute('height', '50')


                const pathsToFill = doc.querySelectorAll('path');

                pathsToFill.forEach((path) => {
                    const d = path.getAttribute('d');
                    if (d && (d.startsWith('M') || d.startsWith('m'))) {
                        path.setAttribute('fill', fillColor);
                    } else {
                        console.error('Invalid path data:', d);
                    }
                });


                const serializer = new XMLSerializer();
                const updatedSvg = serializer.serializeToString(doc);
                const material = item.material;
                const svgPath = item.svgPath;
                const dxfPath = item.dxfPath;


                return { updatedSvg, width, height, totalLength, totalArea, material, svgPath, dxfPath };
            }
        });

        const svgResults = await Promise.all(svgPromises);

        const getloweRate= async (days) => {
           const data = await initApi().get(
                API_URL + API_URLS.GET_HOURLY_RATES_DATA
              );

              return  data.data.find( rate => rate.deliveryDays === days );
        }


        const price_i =   (index, loweRate) => {

            if (svgResults[index]) {

                return  priceCalculator({
                    area: svgResults[index].totalArea,
                    length: svgResults[index].totalLength,
                    materialPrice: svgResults[index].material.price,
                    cuttingSpeed: svgResults[index].material.cuttingSpeed,
                    rate: Number(loweRate.rate),
                    minimalRate: Number(loweRate.minimumPrice),
                    startingFrom: true
                });
            }

            return 0;
        };

         console.log('fileList data', fileList)
        const loweRate = await getloweRate(30)
        const updatedFileList = fileList.map((item, index) => ({
            ...item,
            material: item.material,
            svgContent: svgResults[index]?.updatedSvg,
            svgWidth: svgResults[index]?.width,
            svgHeight: svgResults[index]?.height,
            totalLength: svgResults[index]?.totalLength,
            totalArea: svgResults[index]?.totalArea,
            price: (item.material) ?  price_i(index,  loweRate) : 0,
            pricePerUnit: (item.pricePerUnit) ? item.pricePerUnit : 0
        }))
        
         if (pdfParser) {
             console.log('seeding data')
             setPdfFileContents(updatedFileList);

         }

         if (!pdfParser) {
             setFileContents(updatedFileList);
         }
         return updatedFileList
    };

    const updateProgress = (newProgress) => {
        setProgress(newProgress);
    };

    const fetchFolders = async () => {
        setFoldersList([])
        setLoading(true);
        setLoadingMessage('loading folder data...');
        setProgress(0);

        const res = await getFolders();
        setFoldersList(res.data.child_folders);
        setProgress(50);

        setFilesList(res.data.files);
        setProgress(80);

        fetchSVGs(res.data.files);

        setProgress(100);
        setLoading(false);
        setActionBar((prevState) => ({
            checkbox: { checked: false, indeterminate: false },
            basket:prevState.basket,
            basket_fileContest: prevState.basket_fileContest,
            checkbox: { checked: false},
            selectedFiles: [],
            specified: 0 ,
        }));
        setCheckedState({})
    };

    const fetchFolder = async (id) => {
        setFoldersList([])
        setLoading(true);
        setLoadingMessage('loading folder data...');
        setProgress(0);

        const res = await getFolder(id);
        setProgress(50);

        setFoldersList(res.data.child_folders);
        setFilesList(res.data.folder[0].files);
        fetchSVGs(res.data.folder[0].files);
        setCurrentFolder(res.data.folder[0]);
        setProgress(100);
        setLoading(false);
        setActionBar((prevState) => ({
            checkbox: { checked: false, indeterminate: false },
            basket:prevState.basket,
            basket_fileContest: prevState.basket_fileContest,
            checkbox: { checked: false},
            selectedFiles: [],
            specified: 0 ,
        }));
        setCheckedState({})
    };

const openFolder = (id) => {
         setFileContents([])
      

        setPrevFolder(folderId)
        history.push(`${id}`);
    };

 const updateRoundedProgress = (percentage) => {
         const roundedPercentage = Math.round(percentage / 10) * 10;
         updateProgress(roundedPercentage);
     };

const uploadDXF = async (e, uploadRef) => {
         setLoading(true);
         setLoadingMessage('Uploading...');
         setProgress(0);

         const files = uploadRef.current.files;
         const results = [];
         const totalFiles = files.length;

    for (let i = 0; i < totalFiles; i++) {
             const file = files[i];
             const reader = new FileReader();

             reader.onprogress = (event) => {
                 if (event.lengthComputable) {
                     const percentRead = Math.round((event.loaded / event.total) * 100);
                     updateRoundedProgress((i / totalFiles) * 100 + percentRead / totalFiles);
                 }
             };

             const DXF_TEXT = await new Promise((resolve, reject) => {
                 reader.onload = () => resolve(reader.result);
                 reader.onerror = reject;
                 reader.readAsText(file);
             });




             try {
                 const convertedDxf = await convertDxfSimple(DXF_TEXT, 'dxf', true);
                 results.push({
                     svg: convertedDxf,
                     name: file.name
                 });
             updateRoundedProgress(((i + 1) / totalFiles) * 100);

             } catch (error) {
                 if (error.code == "ERR_BAD_REQUEST") {

                     toast.error("Invalid Dxf File", {
                         position: 'bottom-right',
                         autoClose: 2500,
                         hideProgressBar: true,
                         closeOnClick: true,
                         pauseOnHover: true,
                         draggable: false,
                         progress: undefined,
                     });

                 }

             }

         }

         setLoading(false);
         return results;
     };

     const createProj = async (e, uploadRef, name) => {
         console.log('name name name', name)
         setLoading(true);
         setLoadingMessage('Creating Files...');
         setProgress(0);

         const convertedSvgs = await uploadDXF(e, uploadRef);

         const totalFiles = convertedSvgs.length;

         for (let i = 0; i < totalFiles; i++) {
             const convertedSvg = convertedSvgs[i];
             setProgress((i / totalFiles) * 70);

             try {
                 const file = await createFile({
                     name: name || convertedSvg.name,
                     svg_path: convertedSvg.svg.headers['x-svg-path'],
                     png_path: "",
                     step_path: "",
                     dxf_path: convertedSvg.svg.headers['x-dxf-path'],
                     cw_path: "",
                     folder: (folderId === "main") ? null : folderId,
                     material: null,
                 });


                 //  dxfUpload([file])

                 const svg = await fetchSVGs([file.data]);


                 //  await updateFile(file.data.id, {
                 //      svg_width: parseInt(svg[0].svgWidth.replace(/[^0-9]/g, ''), 10),

                 //      svg_height: parseInt(svg[0].svgHeight.replace(/[^0-9]/g, ''), 10)
                 //  })


                 if (folderId === 'main') {
                     await fetchFolders();
                 } else {
                     await fetchFolder(folderId);
                 }

                 setProgress(((i + 1) / totalFiles) * 70 + 30);
             } catch (err) {
                 console.log(err);
             }
         }

         updateRoundedProgress(100);
         setLoading(false);
         if (name) {
             concatWithPartLibrary(pdfFiles)
         }
     };

     const addFolder = async () => {
        setLoading(true);
        setLoadingMessage('Uploading file...');
        setProgress(0);

        await createFolder({
            name: "new folder",
            parent_id: folderId !== 'main' ? folderId : null,
        });
        if (folderId == 'main') {
            await fetchFolders();
        }
        else
        {
            await fetchFolder(folderId);

        }
        setProgress(100);
        setLoading(false);
    };

     const dxfUpload = function (files, isConfigurator = false) {
         const { convertDxf, drawLoading } = props;

         files.forEach(async (file, index, array) => {
             const lastOne = index + 1 === array.length;
             const materialObject = file.material;
             const quantity = file.quantity;

             const DXF_TEXT = await getFileDxf(file.id);
             await convertDxf(
                 compress(DXF_TEXT.data),
                 file.name,
                 drawLoading,
                 true,
                 lastOne,
                 materialObject,
                 quantity
             );

             if (!isConfigurator) {
                 history.push(`/configurator`);

             }

         });
     };

     const onUpdateFolder = async (id, name) => {

         await updateFolder( id, {
               name: name
           } );

         if (folderId === 'main') {
             await fetchFolders();
         }
         else
         {
             await fetchFolder(folderId);

         }

        }
    

     const handleBack = () => {

        setActionBar((prevState) => ({
            checkbox: { checked: false, indeterminate: false },
            basket:prevState.basket,
            basket_fileContest: prevState.basket_fileContest,
            checkbox: { checked: false},
            selectedFiles: [],
            specified: 0 ,
        }));
        setCheckedState({})

         if (!currentFolder.parentId) {
             history.push('main')
         }
         else {
             history.push(`${currentFolder.parentId}`)
         }
     }
     const addBasket = async function(someFile, files) {

         setActionBar((prevState) =>
             prevState.selectedFiles.length > 0 || someFile
                 ? {
                     ...prevState,
                     basket: [
                         ...prevState.basket,
                         ...(isArray(someFile) ? someFile : [someFile]),
                     ].filter((value, index, array) => array.indexOf(value) === index),
                 }
                 : prevState
         );

     setBasketFileContents(prevData =>  [...prevData, ...files])

     };
       
     const openBasketModal = function() {
         const disableBasket = function(bodyTxt) {
             setModal((prevState) => ({
                 ...prevState,
                 show: true,
                 headerTitle: <FormattedMessage id="CAD.LIBRARY.BASKET" />,
                 body: <span>{bodyTxt}</span>,
                 submitSection: (
                     <>
                         <Skeleton
                             variant="rectangular"
                             width={79}
                             height={39}
                             sx={{ borderRadius: '5px' }}
                         />
                         <Skeleton
                             variant="rectangular"
                             width={197}
                             height={39}
                             sx={{ borderRadius: '5px' }}
                         />
                         <Skeleton
                             variant="rectangular"
                             width={140}
                             height={39}
                             sx={{ borderRadius: '5px' }}
                         />
                     </>
                 ),
             }));
         };

         const toConfigurator = function() {
             setActionBar((prevState) => {
                 dxfUpload(Object.values(prevState.basket_fileContest));
                 return { ...prevState, basket: [] };
             });
             disableBasket('Please await you will be redirected to configurator');
         };


         const toCheckout = function() {

             setActionBar((prevState) => {
                 createCheckout(Object.values(prevState.basket_fileContest), );
                 return { ...prevState, basket: [] };
             });
             setActionBar((prevState) => ({ ...prevState, basket: [] }));
             disableBasket('Please await you will be redirected to checkout');
         };

         setModal((prevState) => ({
             ...prevState,
             show: true,
             headerTitle: <FormattedMessage id="CAD.LIBRARY.BASKET" />,
             body:
             basketFileContents && basketFileContents.length > 0 ? (
                     <OverviewPart
                     fileContents={basketFileContents?.filter((file) =>
                             actionBarState.basket.includes(file.id)
                         )}
                         setActionBar={setActionBar}
                     />
                 ) : (
                     'empty'
                 ),
             submitSection: (
                actionBarState.basket.length  ?
                    <>
                    <BootstrapButton onClick={closeModal} variant={'secondary'}>
                        <FormattedMessage id="CANCEL" />
                    </BootstrapButton>
                    <BootstrapButton onClick={toConfigurator}>
                        <FormattedMessage id="CAD.LIBRARY.GO_CHECKOUT" />
                    </BootstrapButton>
                    <BootstrapButton onClick={toCheckout}>
                        <FormattedMessage id="CAD.LIBRARY.GO_CONFIGURATOR" />
                    </BootstrapButton>
                </>
                : ""
                
             ),
         }));
     };


     const closeModal = function() {
         setModal((prevState) => ({
             ...prevState,
             show: false,
         }));
     };


     const filteredFileList = fileContents.filter(file => 
         file?.name?.toLowerCase().includes(searchTerm.toLowerCase()) 
    );



     return (
        <>

            <ActionBar
                actionBarState={actionBarState}
                setActionBarState={setActionBar}
                fetchFolder = {fetchFolder}
                fetchFolders = {fetchFolders}
                openBasketModal={openBasketModal}
                fileList={filesList}
                fileContents={fileContents}
                currentFolder={currentFolder}
                dxfUpload={dxfUpload}
                addBasket={addBasket}
                setCheck={setCheck}
                check={check}
                filteredFileList={filteredFileList}
                setSearchTerm={setSearchTerm}
                choiceData={modalState.choiceData}

         
             />

            <ToolbarComponent
                uploadRef={uploadRef}
                progress={progress}
                loading={loading}
                loadingMessage={loadingMessage}
                createProj={createProj}
                folderId={folderId}
                currentFolder={currentFolder}
                createFolder={addFolder}
                handleBack={handleBack}
                 handleDrop={handleDrop}
                 isLoading={isLoading}
                 user={props.user}
            />

             {(!pdfFileContents.length) ?
                 <>
            <FolderComponent
                folders={foldersList}
                openFolder={openFolder}
                getFolder={getFolder}
                getFiles={getFiles}
                onUpdateFolder={onUpdateFolder}
                fetchSingleFolder = {fetchFolder}
                fetchFolders = {fetchFolders}
            />
            <FileComponent
                fileList={filesList}
                fileContents={filteredFileList}
                checkBox={actionBarState.checkbox}
                setActionBar={setActionBar}
                actionBarState={actionBarState}
                choiceData={modalState.choiceData}
                materialById={materialById}
                createCheckout={createCheckout}
                fetchFolder = {fetchFolder}
                fetchFolders = {fetchFolders}
                addBasket={addBasket}
                dxfUpload={dxfUpload}
                handleSpecifiedParts={handleSpecifiedParts}
                setCheck={setCheck}
                checkedState = {checkedState}
                setCheckedState ={setCheckedState}
                         openModalConf igurator={openModalConfigurator}
                         setOpenModalConfigurator={setOpenModalConfigurator}
                         init={init}
                         pdfFiles={pdfFiles}
                         concatWithPartLibrary={concatWithPartLibrary}
                     />

                 </>

                 :
                 <>


                 <FileComponent
                     fileList={pdfFileContents}
                     fileContents={pdfFileContents}
                     checkBox={actionBarState.checkbox}
                     setActionBar={setActionBar}
                     actionBarState={actionBarState}
                     choiceData={modalState.choiceData}
                     materialById={materialById}
                     createCheckout={createCheckout}
                     fetchFolder={fetchFolder}
                     fetchFolders={fetchFolders}
                     addBasket={addBasket}
                     dxfUpload={dxfUpload}
                     handleSpecifiedParts={handleSpecifiedParts}
                     setCheck={setCheck}
                     checkedState={checkedState}
                     setCheckedState={setCheckedState}
                     openModalConf igurator={openModalConfigurator}
                 setOpenModalConfigurator={setOpenModalConfigurator}
                 init={init}
                     uploadRef={uploadRef}
                     createProj={createProj}
                     setParsedData={setParsedData}
                     parsedData={parsedData}
                     pdfFiles={pdfFiles}
                     concatWithPartLibrary={concatWithPartLibrary}

             />
                 </>

             }
            <Modal
                show={modalState.show}
                onHide={closeModal}
                className="oneModal"
                centered
            >
                <Modal.Header closeButton >
                    {' '}
                    <Modal.Title> {modalState.headerTitle} </Modal.Title>{' '}
                </Modal.Header>

                <Modal.Body> {modalState.body} </Modal.Body>

                <Modal.Footer>{modalState.submitSection}</Modal.Footer>
            </Modal>

        </>
    );
}

const mapStateToProps = ({ draw, auth: { user } }) => ({
    draw,
    drawLoading: draw.loading,
    user,

});

const mapDispatchToProps = {
    createProject: (ARRAY_DXF, type, fileType, quantity) =>
        drawActions.createProject(ARRAY_DXF, type, fileType, quantity),
    updateNewFile: (urls) => drawActions.updateNewFile(urls),
    materialDataRequest: () => drawActions.materialDataRequest(),
    convertDxf: (data, name, loading, withAbort, lastOne, materialObject, quantity) =>
        drawActions.convertDxfRequest(data, name, loading, withAbort, lastOne, materialObject, quantity),
    updateDrawSVGarray: (uploadedSvgs) =>
        drawActions.updateDrawSVGarray({ SVG: uploadedSvgs }),


};

export default injectIntl(
    connect(mapStateToProps, mapDispatchToProps)(withRouter(PartLibrary))
);

    