import makerjs, { path } from 'makerjs';

import { ELEMENTS } from '../../../../../common/constants/elements/elements';

async function parseSVG(updateDrawSVG, id, updateSelectedSVG, svg) {
  // const { updateDrawSVG, id, updateSelectedSVG, svg } = this.props;

  if (!svg) return;

  const parser = new DOMParser();
  const doc = parser.parseFromString(svg, 'image/svg+xml');
  const groups = [...doc.getElementsByTagName(ELEMENTS.GROUP)];
  const pathList = [...doc.getElementsByTagName(ELEMENTS.PATH)];
  const circleList = [...doc.getElementsByTagName(ELEMENTS.CIRCLE)];
  const models = [];
  const paths = [];

  let firstGroup = groups[0];
  let scale = firstGroup.getAttribute('transform');

  if (typeof id != 'number') {
    if (scale) {
      this.setState((prevState) => ({
        ...prevState,
        rotate: true,
      }));
    } else {
      this.setState((prevState) => ({
        ...prevState,
        rotate: false,
      }));
    }
  }

  if (pathList.length === 0 && circleList.length === 0) {
    return;
  }

  // Extract all paths and add them to models["path"]
  for (let [index, elem] of Object.entries(pathList)) {
    let pathData = elem.getAttribute('d');
    try {
      var model = makerjs.importer.fromSVGPathData(pathData);
      if (this.state.rotate && typeof id != 'number') {
        model = makerjs.model.mirror(model, false, true);
      }
      models['path' + index] = model;
    } catch (e) {
      console.error(e);
    }
  }

  // Extract all circles
  for (let [index, elem] of Object.entries(circleList)) {
    let cx = parseFloat(elem.getAttribute('cx'));
    let cy = parseFloat(elem.getAttribute('cy'));
    let r = parseFloat(elem.getAttribute('r'));

    try {
      let circle = new makerjs.paths.Circle([cx, cy], r);
      // Mirror ourselves, makerjs.model.mirror has no effect

      if (typeof id === 'number') {
        circle.origin[1] = circle.origin[1] - circle.origin[1] * 2;
      }

      paths['path' + index] = circle;
    } catch (e) {
      console.error(e);
    }
  }

  let modelWrapper = {
    models: models,
    paths: paths,
  };

  // modelWrapper = makerjs.model.zero(modelWrapper);
  // modelWrapper = makerjs.model.simplify(modelWrapper);
  // Export the model to a new SVG, this will convert the circles to paths only
  // We need the width, height from the center of the model
  const svgPathsOnly = makerjs.exporter.toSVG(modelWrapper, {
    useSvgPathOnly: true,
  });

  const docPathsOnly = parser.parseFromString(svgPathsOnly, 'image/svg+xml');
  const pathList2 = [...docPathsOnly.getElementsByTagName(ELEMENTS.PATH)];
  const models2 = [];

  for (let [index, elem] of Object.entries(pathList2)) {
    let pathData = elem.getAttribute('d');
    try {
      //parentModel: IModel, onPath: IPath, baseline?: number, reversed?: boolean, contain?: boolean
      let pathsFromPathData = makerjs.importer.fromSVGPathData(pathData);
      models2['path' + index] = pathsFromPathData;
    } catch (e) {
      console.error('import from svg', e);
    }
  }

  // First, separate elements in array: it's important to separate by element count not by vertex count
  let chains = [];
  let loosePaths = [];

  const models_separated = [];
  const firstLine = { newLine: true, vertex: false };

  //----------------------------------------------------------------------------------------------------

  this.updatePreviewAfter = (chainsClonedArg, notLast, isUpdateId) => {
    let chains = chainsClonedArg;

    chains = chains
      .filter((chain) => chain /* && chain.endless */)
      .map((chain) => ({ ...chain, layer: 'cuttings' }));

    let svgEl = docPathsOnly.getElementsByTagName('svg');
    svgEl = svgEl && svgEl[0];

    let width =
      (svgEl &&
        svgEl.width &&
        svgEl.width.baseVal &&
        svgEl.width.baseVal.value) ||
      0;
    let height =
      (svgEl &&
        svgEl.height &&
        svgEl.height.baseVal &&
        svgEl.height.baseVal.value) ||
      0;

    const dxf = makerjs.exporter.toDXF(modelWrapper);

    // AREA TMP
    //let area = Math.abs(paperPath.area);
    let area = Math.abs(width * height);

    this.setState((prevState) => ({
      ...prevState,
      loading: notLast
        ? prevState.loading
        : { ...prevState.loading, [id]: false },
      chainList: notLast
        ? prevState.chainList
        : { ...prevState.chainList, [id]: chains },
      parsedSVG: svgPathsOnly,
      viewBoxWidh: width,
      viewBoxHeight: height,
    }));

    const previewWithSvg = this.getPreview({
      ...this.props,
      chains,
      loosePaths,
      viewBox: {
        width,
        height,
      },
      loadingStatus: isUpdateId ? false : true,
    });

    updateSelectedSVG({
      viewBox: {
        width,
        height,
      },
      chains,
      area,
      loosePaths,
      model: modelWrapper,
      preview: previewWithSvg,
      dxf,
    });

    if (isUpdateId) {

      let newUploadedSVGs = this.props.uploadedSvgs.map((SVGitem) => {
        return SVGitem.id === id
          ? {
              ...SVGitem,
              viewBox: {
                width,
                height,
              },
              chains,
              area,
              loosePaths,
              model: modelWrapper,
              preview: previewWithSvg,
              dxf,
              selectedTypeMaterial: SVGitem.selectedTypeMaterial,
            }
          : SVGitem;
      });
      
      this.props.updateDrawSVGarray({ SVG: newUploadedSVGs });
    } else {
      updateDrawSVG({ id });
    }
  };

  const updatePreviewAfter = this.updatePreviewAfter;

  //---------------------------------------------------------------------------

  if (!this.state.chainList[id]) {
    Object.values(models2.path0?.paths)
      .map((path) => {
        if (path.type === 'line') {
          return {
            type: 'line',
            end: [path.end[0], path.end[1]],
            origin: [path.origin[0], path.origin[1]],
          };
        } else if (path.type === 'arc') {
          return {
            type: 'arc',
            radius: path.radius,
            origin: path.origin,
            startAngle: path.startAngle,
            endAngle: path.endAngle,
          };
        } else if (path.type === 'circle') {
          return {
            type: 'circle',
            radius: path.radius,
            origin: path.origin,
          };
        }
      })
      .flat()
      .forEach(async (path_, index, array) => {
        if (firstLine.newLine) {
          firstLine.newLine = false;
          firstLine.vertex = path_;
          models_separated.push([path_]);
        } else if (!firstLine.vertex) {
          firstLine.vertex = path_;
          models_separated.push([path_]);
        } else {
          const OriginCoordinates =
            firstLine.vertex.type === 'arc'
              ? [
                  firstLine.vertex.origin[0] +
                    Math.cos((firstLine.vertex.startAngle * Math.PI) / 180) *
                      firstLine.vertex.radius,
                  firstLine.vertex.origin[1] +
                    Math.sin((firstLine.vertex.startAngle * Math.PI) / 180) *
                      firstLine.vertex.radius,
                ]
              : firstLine.vertex.origin;

          const EndCoordinates =
            path_.type === 'arc'
              ? [
                  path_.origin[0] +
                    Math.cos((path_.endAngle * Math.PI) / 180) * path_.radius,
                  path_.origin[1] +
                    Math.sin((path_.endAngle * Math.PI) / 180) * path_.radius,
                ]
              : path_.end;

          let nextType = false;

          queueMicrotask(() => {
            if (array[index++] && path_.type === 'arc') {
              if (array[index++]?.type === 'arc') {
                const startPoint = [
                  path_.origin[0] +
                    Math.cos((path_.startAngle * Math.PI) / 180) * path_.radius,
                  path_.origin[1] +
                    Math.sin((path_.startAngle * Math.PI) / 180) * path_.radius,
                ];
                const endPoint = [
                  path_.origin[0] +
                    Math.cos((path_.endAngle * Math.PI) / 180) * path_.radius,
                  path_.origin[1] +
                    Math.sin((path_.endAngle * Math.PI) / 180) * path_.radius,
                ];

                const nextArcAngel =
                  (array[index++]?.endAngle % 360) -
                  (array[index++]?.startAngle % 360);
                const currentAngle =
                  (path_.endAngle % 360) - (path_.startAngle % 360);

                if (
                  nextArcAngel + currentAngle === 360 ||
                  (startPoint[0] * endPoint[0] > 0 &&
                    startPoint[1] * endPoint[1] > 0 &&
                    Math.abs(Math.abs(startPoint[0]) - Math.abs(endPoint[0])) <=
                      0.01 &&
                    Math.abs(Math.abs(startPoint[1]) - Math.abs(endPoint[1])) <=
                      0.01)
                ) {
                  nextType = true;
                }
              }
            }

            if (
              path_.type === 'circle' ||
              (path_.endAngle === 360 && path_.startAngle == 0)
            ) {
              nextType = true;
            }
          });

          if (
            (OriginCoordinates[0] * EndCoordinates[0] > 0 &&
              OriginCoordinates[1] * EndCoordinates[1] > 0 &&
              Math.abs(
                Math.abs(OriginCoordinates[0]) - Math.abs(EndCoordinates[0])
              ) <= 0.01 &&
              Math.abs(
                Math.abs(OriginCoordinates[1]) - Math.abs(EndCoordinates[1])
              ) <= 0.01) ||
            nextType
          ) {
            models_separated[models_separated.length - 1].push(path_);
            firstLine.vertex = undefined;

            if (models_separated[models_separated.length - 1].length > 50) {
              firstLine.newLine = true;
            }
          } else {
            models_separated[models_separated.length - 1].push(path_);
          }
        }
      });

    // Extract the chains, contain flag true exports the base models with models inside
    models_separated.forEach((item) => {
      chains = [
        ...chains,
        ...makerjs.model.findChains(
          {
            models: {
              path0: {
                models: models2.path0.models,
                paths: item,
              },
            },
          }, //{ models: models2 }
          function(chainsFound, loosePathsFound /*layers*/) {
            if (chainsFound.length) {
              chains = chainsFound;
            }
            if (loosePathsFound.length) {
              loosePaths = loosePathsFound;
            }
          },
          {
            pointMatchingDistance: 0.001,
            contain: true,
          }
        ),
      ];
    });

    this.updatePreviewAfter(chains, true);

    await getContainment(chains);
  } else {
    this.updatePreviewAfter(this.state.chainList[id], false);
  }

  //----------------------------------------------------------------------------------------------------

  async function getContainment(allChains) {
    var chainsAsModels = allChains.map((c) => makerjs.chain.toNewModel(c));
    var parents = [];
    const chainsCloned = allChains.map((item) => item);

    //see which are inside of each other
    await allChains.forEach(async (chainContext, i1) => {
      var wp = chainContext.links[0].walkedPath;
      var firstPath = path.clone(wp.pathContext, wp.offset);

      if (!chainContext.endless && i1 !== allChains.length - 1) {
        return;
      }

      const doThen = () => {
        allChains.forEach(async (otherChain, i2) => {
          if (chainContext === otherChain) {
            return;
          }

          // if (!otherChain.endless) {
          //   return;
          // }

          if (
            makerjs.measure.isPointInsideModel(
              makerjs.point.middle(firstPath),
              chainsAsModels[i2]
            )
          ) {
            //since chains were sorted by pathLength, the smallest pathLength parent will be the parent if contained in multiple chains.
            parents[i1] = otherChain;

            chainsCloned[i2] = {
              ...chainsCloned[i2],
              contains: [
                ...(chainsCloned[i2]?.contains
                  ? chainsCloned[i2]?.contains
                  : []),
                chainContext,
              ],
            };

            chainsCloned[i1] = null;
          }
        });
      };

      await new Promise((resolve) => {
        setTimeout(() => {
          resolve(doThen());
        }, 1);
      }).then(async () => {
        if (allChains.length - 1 === i1) {
          updatePreviewAfter(chainsCloned, false, true);
        }
      });
    });
  }
}

export default parseSVG;
