import React, { useRef } from 'react';
import Button from "@mui/material/Button";
import { LuImport } from "react-icons/lu";
import { FiFolderPlus } from "react-icons/fi";
import { useParams } from 'react-router-dom';
import Backdrop from '@mui/material/Backdrop';
import LinearProgress from '@mui/material/LinearProgress';
import {MdOutlineChevronLeft} from "react-icons/md";
import { FormattedMessage } from 'react-intl';
import { useDropzone } from 'react-dropzone';
import { Box, Typography, Paper, CircularProgress } from '@mui/material';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import MemoryIcon from '@mui/icons-material/Memory';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing'

function Loader({ message, progress }) {
    return (
        <Backdrop open={true} >
            <div style={{ textAlign: 'center', width: '50%' }}>
                <p>{message}</p>
                <LinearProgress variant="determinate" value={progress} />
                <p>{`${progress}%`}</p>
            </div>
        </Backdrop>
    );
}

const FileUpload = ({ onDrop, isLoading }) => {
    const dropzoneRef = useRef(null);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: 'application/pdf',
        onDragEnter: () => {
            if (dropzoneRef.current) {
                dropzoneRef.current.style.borderColor = 'red';
            }
        },
        onDragLeave: () => {
            if (dropzoneRef.current) {
                dropzoneRef.current.style.borderColor = '#1976d2'; 
            }
        },
    });

    return (
        <div
            ref={dropzoneRef}
            variant="outlined"
            {...getRootProps()}

        >
            {isLoading && (
              
<Box
  sx={{
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    zIndex: 10,
    padding: 2,
    borderRadius: 1,
  }}
>
  <CircularProgress sx={{ color: '#076280', mr: 2 ,       animation: 'spin 1s linear infinite',}} />
  {/* <PictureAsPdfIcon
    sx={{
      fontSize: 40,
      color: '#D80000',
      animation: 'spin 2s linear infinite',
      mr: 2,
    }}
  />
  */}

  <Typography variant="body1" sx={{ ml: 2 }}>
    Parsing data from PDF, please wait...
  </Typography>
   {/* <MemoryIcon
    sx={{
      fontSize: 40,
      color: '#076280',
      animation: 'pulse 1.5s ease-in-out infinite',
      mr: 2,
    }}
  /> */}
</Box>
            )}
            <input {...getInputProps()} disabled={isLoading} />

            <button
    type={'button'}
    className={'btn btn-bold btn-outline-brand'}
    style={{
      marginLeft: '20px'
    }}
>
                <FormattedMessage id="CAD.LIBRARY.ORDER_WITH_PDF" />

            </button>
        </div>
    );
};


export default function ToolBarComponent({uploadRef, progress, loading, loadingMessage, createProj,  currentFolder, createFolder, handleBack, handleDrop, isLoading, user}) {

    const { folderId } = useParams();

    return (
        <div className={'drive_window'}>
            {loading && <Loader message={loadingMessage} progress={progress} />}

            <div className="drive_window-toolbar">

                    <Button
                    variant="text"
                    className={'drive_window-back'}
                    onClick={handleBack}
                >
                    {folderId !== "main" && (
                        <MdOutlineChevronLeft style={{fontSize: '20px'}}/>
                    )}
                    { (folderId === "main") ? "MAIN" : 'back'}
                </Button>

                <LuImport
                    className="actionIcons drive_window"
                    style={{ transform: 'rotate(180deg)' }}
                    onClick={() => uploadRef.current.click()}
                />

                <input
                    ref={uploadRef}
                    type="file"
                    accept=".dxf"
                    style={{ display: 'none' }}
                    multiple={true}
                    onChange={(e) => createProj(e, uploadRef)}
                />

            <FiFolderPlus
                    className="actionIcons"
                    onClick={() => createFolder()}
                />


              <FileUpload onDrop={handleDrop} isLoading={isLoading} /> 

            </div>
        </div>
    )

}
