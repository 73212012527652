import React, { useEffect, useState, useReducer } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, useIntl } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Modal, Button } from 'react-bootstrap';
import CircularProgress from '@mui/material/CircularProgress';
import { FaDownload } from 'react-icons/fa6';
import { SlBasketLoaded } from 'react-icons/sl';
import { useHistory, useParams } from 'react-router-dom';
import { Unstable_Popup as BasePopup } from '@mui/base/Unstable_Popup';
import { isNull } from 'lodash';
import { updateFile, getFileDxf, deleteFile } from "../../../crud/partLibrary.crud";
import {priceCalculator} from "../../../partials/content/priceCalculator";
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import {Button as BootstrapButton} from "react-bootstrap";
import Draw from '../../../pages/home/configurator/Draw/Draw/Draw';
import { LuImport } from "react-icons/lu";


import MaterialSelector from "../../../pages/home/mypartlibrary/fileComponent/materialSelector";
import CheckBox from "../../checkBox";
import {toast} from "react-toastify";
import { API_URL } from '../../../common/constants/api/apiConstants';
import { ROUTES } from '../../../common/constants/routes/routes';

import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

function FileComponent({
                           fileList,
                           fileContents,
                           setFileContents,
                           onClick,
                           uptFile,
                           dltFile_s,
                           materialById,
                           addBasket,
                           fetchFolder,
                           fetchFolders,
                           setActionBar,
                           actionBarState,
                           handleSpecifiedParts,
                           setChek,
                           checkedState,
                           setCheckedState,
    init,
    openModalConfigurator,
    setOpenModalConfigurator,
    uploadRef,
    createProj,
    setParsedData,
    parsedData,
    pdfFiles,
    concatWithPartLibrary,
                           ...props
                       }) {



    const history = useHistory();
    const {folderId} = useParams();

    const [, forceUpdate] = useReducer((x) => x + 1, 0);

    const [deleteModalVisibility, setDeleteModalVisibility] = useState(false);
    const [deleteId, setDeleteId] = useState(undefined);

    const [ternary, setTernary] = useState({ anchor: null, file: {} });

    const [specifyModalVisibility, setSpecifyModalVisibility] = useState(false);
    const [specifyIndex, setSpecifyIndex] = useState(undefined);
    const [selectedChoice, setSelectedChoice] = useState(null);

    const [materialObject, setMaterialObject] = useState({});

    const [porcFileId, setPorcFileId] = useState();
    const [selectedMaterial, setSelectedMaterial] = useState();


    const intl = useIntl();

    const handleCheckboxChange = (fileID, checked, file) => {
        const newState = { ...checkedState };
        newState[fileID] = checked;

        const values = Object.values(newState);
        const indeterminate = values.includes(true);

        const checkAll = false
      
        setActionBar((prevState) => ({
            ...prevState,
            checkbox: {
                checked: checkAll,
                indeterminate: !checkAll && indeterminate,
            },
            selectedFiles: Object.keys(newState)
                .filter((fileID) => newState[fileID])
                .map((fileID) => Number(fileID)),
        }));

        setCheckedState(newState);

        handleSpecifiedParts(file)
    };

    const updateDeleteModalVisibility = (show) => {
        setDeleteModalVisibility(show);
    };

    const updateSpecifyModalVisibility = (show) => {
        setSpecifyModalVisibility(show);
    };

    const fileDeleteModal = (event, fileId) => {
        event.stopPropagation();
        setDeleteId(fileId);
        updateDeleteModalVisibility(true);
    };

    const fileSpecifyModal = (event, index) => {
        event.stopPropagation();
        setSpecifyIndex(index);

        updateSpecifyModalVisibility(true);

    };

    const handleChoiceSelection = (choice) => {
        setSelectedChoice(choice);
    };

    const removeFile = async (fileId) => {
        try {
          await deleteFile(fileId);
          
          setActionBar(prevState => ({
            ...prevState,
            selectedFiles: prevState.selectedFiles.filter(id => id !== fileId),
            basket: prevState.basket.filter(id => id !== fileId)
          }));
    
          updateDeleteModalVisibility(false);
    
          if (folderId === 'main') {
            await fetchFolders();
          } else {
            await fetchFolder(folderId);
          }
        } catch (error) {
          console.error('Error deleting file:', error);
        }
    };

    const downloadDXF = async function(fileId, fileName) {
        try {
            const link = document.createElement('a');
            link.href =  `${API_URL}/api/file/${fileId}/download/dxf`
            link.download = `${fileName}.DXF`;
            link.click();
        } catch (err) {
            toast.error(err.message, {
                position: 'bottom-right',
                autoClose: 2500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
            });
        } finally {
            toast.success('File downloaded', {
                position: 'bottom-right',
                autoClose: 2500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
            });
        }
    };

    const specifyFile = async (file, length, area, choice, files) => {

            const calculatedPrice = priceCalculator({
                area,
                length,
                materialPrice: choice.price,
                cuttingSpeed: choice.cutting_speed,
            });


        await updateFile(file.id, {
            material: materialObject[choice.id].id,
            name: file.name,
            svg_path: file.svgPath,
            step_path: "",
            dxf_path: file.dxfPath,
            cw_path: "",
            folder: (folderId === "main") ? null : folderId,
        });

        updateSpecifyModalVisibility(false);

        toast.success('success', {
            position: 'bottom-right',
            autoClose: 2500,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
        });

        if (folderId === 'main') {
            await fetchFolders();
        }
        else
        {
            await fetchFolder(folderId);
        }
      await  handleSpecifiedParts(file)

        concatWithPartLibrary(pdfFiles)

    };

    const redirect_CAD = async function(file_id, name, material) {
        const getDxf = await getFileDxf(file_id)
        const DXF_TEXT = getDxf.data
        window.cutwise = { DXF_PROJECT: { DXF_TEXT, file_id, name, material: material ? material :  {}, folderId: folderId } };

        history.push(`${ROUTES.SKETCHER}`);

    }

    useEffect(() => {
        if (props.choiceData) {
            const object = materialById(props.choiceData);
            setMaterialObject(object);
        }
    },  [props.choiceData])

    const {
        checkBox: { checked: allCheck, indeterminate } = { checked: false, indeterminate: false },
    } = props;

    useEffect(() => {
        if (!(indeterminate && !allCheck)) {
            const newState = fileList.reduce(
                (accum, file) => ({ ...accum, [file.id]: allCheck }),
                {}
            );
            setCheckedState(newState);
        }
    }, [allCheck]);

    useEffect(() => {
        forceUpdate();
    }, [JSON.stringify(fileContents)]);

    const openDrawModal = (state, fileId, index) => {


        if (!fileId) {
            return
        }


        if (fileContents[index].material) {

            const materialObj = materialObject[fileContents[index].material.id]
            const selectedMaterialObj = fileContents[index].material
            selectedMaterialObj['color_code'] = materialObj.color_code

            setSelectedMaterial(selectedMaterialObj)

        }

        setPorcFileId(fileId)
        setOpenModalConfigurator(state)
    }

    if(!fileList.length)
    {
        return(
        <h2 className="mypartlibrary-title" style={{
                       textAlign: "center",
                       paddingTop: '10%'
                       }}>
                {/* <FormattedMessage id="CAD.LIBRARY.EMPTY_FOLDER" /> */}
        </h2>
        )
    }

    return (
        <>
            {
             fileContents.map((file, index) => (
              <div
                  key={index + file.name}
                  className={`file-component ${checkedState[file.id] ? 'checked' : ''}`}
              >
                     <div className="column file-component_info" >
                      <div className="checkbox">
                             <CheckBox
                              checked={checkedState[file.id] ? 'checked' : ''}
                              onClick={(e) => handleCheckboxChange(file.id, e.target?.checked, file)}
                              indeterminate={checkedState[file.id] ? 'checked' : ''}
                                 disable={!file.id ? true : false}
                          />
                      </div>

                         <div className="file-component_img product-images" style={{ width: "unset", cursor: 'pointer' }}

                             onClick={() => {

                                 (file.id) ? props.dxfUpload([file], true) : ""
                                 openDrawModal(true, file.id, index)
                             }}


                         >
                             {/* {`${process.env.REACT_APP_API_URL}/${file.svgPath}` ? x*/}

                             {
                                 !file.id ?
                                     (<>
                                         <CloudUploadIcon
                                             color="primary"
                                             style={{ fontSize: 40, color: "#D80000", width: '150px', height: '100px' }}
                                             onClick={() => uploadRef.current[index].click()}
                                         />
                                         <input
                                             ref={(el) => (uploadRef.current[index] = el)}
                                             type="file"
                                             accept=".dxf"
                                             style={{ display: 'none' }}
                                             multiple={true}
                                             onChange={(e) => createProj(e, uploadRef, file.name)}
                                         />
                                     </>
                                     )

                                     :

                                     (<div
                                         id={`svg-${file.id}`}
                                  dangerouslySetInnerHTML={{__html: file.svgContent}}
                                  style={{
                                      padding: '10px',
                                      display: 'flex',
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                      width: '150px',
                                      height: '100px',
                                      overflow: 'visible', 
                                  }}

                              />
                                     )
                             }


                      </div>

                      <div className="file-component_description">
                          <div className="item-row">
                              {file.name && (
                                     <strong style={{
                                         fontSize: '14px',
                                         whiteSpace: 'nowrap',
                                         textOverflow: 'ellipsis',
                                         overflow: 'hidden',
                                         maxWidth: '200px'
                                     }}>{file.name}</strong>
                              )}
                          </div>

                          <div className="item-row">
                              <strong>
                                  <FormattedMessage id="CAD.LIBRARY.MATERIAL" />:
                              </strong>

                              {file.material ? (
                                  file.material.name
                              ) : (
                                  <FormattedMessage id="CAD.LIBRARY.NOT_SPECIFIED" />
                              )}

                          </div>

                          <div className="item-row">
                              <strong>
                                  <FormattedMessage id="CAD.LIBRARY.COLOR" />:
                              </strong>
                              {file.material ? (
                                  parseFloat(Number(file.material.thickness)) + ' ' + 'mm' + ' ' + (file.material[`color_${intl.locale}`] ? file.material[`color_${intl.locale}`] : file.material[`color_en`] )
                              ) : (
                                  <FormattedMessage id="CAD.LIBRARY.NOT_SPECIFIED" />
                              )}
                          </div>

                          {file.svgWidth && file.svgHeight && (
                              <div className="item-row">
                                  <strong>
                                      <FormattedMessage id="CAD.LIBRARY.SIZE" />:
                                  </strong>
                                  {/* {Number(file.svgWidth)} mm x {Number(file.svgHeight)} mm */}
                                  {Number(file.svgWidth.replace('mm', '')).toFixed(1) -10} x{' '}
                                  {Number(file.svgHeight.replace('mm', '')).toFixed(1) -10}mm
                              </div>
                          )}
                      </div>  

                      <div className="file-component_description" style={{
                        marginTop: '7px',
                        textAlign: 'center',
                        minWidth: '0px'
                      }}>


                        <div className="item-row">
                        {file.price ?  <strong>  <FormattedMessage id="CAD.LIBRARY.STARTING_FROM"/> </strong> : "" }
                        </div>


                        <div className="item-row">


                                 <div className="file-component_price" style={{
                                     width: '100px'
                                 }}> 

                          {file.price ? (
                              '€ ' + file.price
                          ) : (
                              <span
                                  style={{
                                      display: 'inline-block',
                                      color: '#D80000',
                                      fontSize: '12px',
                                      lineHeight: 1.2,
                                  }}
                              >


             <strong>  <FormattedMessage id="CAD.LIBRARY.NOT_SPECIFIED_PRICE_UNAVAILABLE"/></strong>
              </span>
                          )}

                                     {

                                         file.pricePerUnit && file.price ? file.pricePerUnit != file.price ? (
                                             <Tooltip title="Price in PO (PDF) no longer matches CutWise; price has changed">
                                                 <IconButton>
                                                     <WarningAmberIcon style={{ color: '#D80000' }} />
                                                 </IconButton>
                                             </Tooltip>) : "" : ""
                                     }
                      </div>
                            </div>
                      </div>

                  </div>

                  <div className="column file-component_actions">
                      <div className="hide-mobile text-center">
                          <button
                              type={'button'}
                              className={'btn btn-bold btn-outline-brand'}
                              onClick={() => redirect_CAD(file.id, file.name, file.material)}
                                 disabled={!file.id ? true : false}
                          >
                              <FormattedMessage id="CAD.LIBRARY.EDIT_IN_CUTWISE_CAD"/>
                          </button>
                      </div>

                      <div className="hide-mobile text-center">
                          <button
                              type={'button'}
                              className={'btn btn-bold btn-outline-brand'}
                              onClick={(e) => fileSpecifyModal(e, index)}
                                 disabled={!file.id ? true : false}

                          >
                              {file.material ? (
                                  <FormattedMessage id="CAD.LIBRARY.RE_SPECIFY"/>
                              ) : (
                                  <FormattedMessage id="CAD.LIBRARY.SPECIFY"/>
                              )}
                          </button>
                      </div>


                      <BasePopup open={!isNull(ternary.anchor)} anchor={ternary.anchor}
                                 onMouseLeave={() => setTernary(prevState => ({...prevState, anchor: null, file: {}}))}>
                          <div className='ternary'>
                              <BootstrapButton
                                  onClick={() => {
                                      props.dxfUpload([ternary.file]);
                                      setTernary(prevState => ({...prevState, anchor: null}))
                                  }}
                                  style={{boxShadow: 'unset', fontSize: '10px'}}
                                     disabled={!file.id ? true : false}

                                  
                              >
                                  <FormattedMessage id="CAD.LIBRARY.GO_CHECKOUT"/>
                              </BootstrapButton>

                              <BootstrapButton
                                  onClick={() => {
                                      props.createCheckout([ternary.file]);
                                      setTernary(prevState => ({...prevState, anchor: null}))
                                  }}
                                  style={{opacity: ternary.file.material ? 1 : .3, boxShadow: 'unset',  fontSize: '10px', marginLeft: '10px'}}
                                     disabled={!ternary.file.material || !file.id ? true : false}
                              >
                                  <FormattedMessage id="CAD.LIBRARY.GO_CONFIGURATOR"/>
                              </BootstrapButton>
                          </div>
                      </BasePopup>

                      <div className="hide-mobile">
                          <button
                              type={'button'}
                              className={'btn btn-brand btn-bold'}
                              disabled={ternary.disable}
                                 onClick={(e) => 
                                  setTernary({
                                      anchor: ternary.anchor ? null : e.currentTarget,
                                      file: file,
                                  })
                              }
                          >
                              {ternary.disable ? (
                                  <CircularProgress/>
                              ) : (
                                  <FormattedMessage id="CAD.LIBRARY.ORDER"/>
                              )}
                          </button>
                      </div>

                         <div
                             onClick={(e) => {
                                 file.material && addBasket(file.id, [file])
                                 file.material && init(e, file.id)

                             }
                             }
                             disabled={!file.id ? true : false}

                         >

                        <Tooltip title={ (file.material) ? "add to Basket" : " Pleas Specify First" } style={{fontSize: '20px'}}>
                            <IconButton>
                            <SlBasketLoaded
                                         data-addtocart
                                style={{
                                    fontSize: '1em',
                                    cursor: file.material ? 'pointer' : 'not-allowed',
                                }}
                                color={'#404040'}


                                // onClick={() => handleAddToCart(file.id)}
                            />
                            </IconButton>
                        </Tooltip>
                      </div>

                      <div className="file-component_download">
                          <FaDownload
                              style={{fontSize: '1.5em', cursor: 'pointer'}}
                              color={'#404040'}
                              onClick={() => downloadDXF(file.id, file.name)}
                          />
                      </div>

                      <div className="file-component_remove">
                          <FontAwesomeIcon
                              icon={regular('trash-can')}
                              style={{fontSize: '1.5em'}}
                              color={'#404040'}
                              cursor={'pointer'}
                              onClick={(e) => fileDeleteModal(e, file.id)}
                          />
                      </div>
                  </div>


              </div>

          ))

            }

            <Modal
                className="cad-modal"
                show={deleteModalVisibility}
                onHide={() => updateDeleteModalVisibility(false)}
                size={'md'}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <FormattedMessage id="CAD.LIBRARY.DELETE_PART"/>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FormattedMessage id="CAD.LIBRARY.CONFIRM_DELETE"/>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant={'secondary'}
                        onClick={() => updateDeleteModalVisibility(false)}
                    >
                        <FormattedMessage id="CANCEL"/>
                    </Button>
                    <Button onClick={() => removeFile(deleteId)}>
                        <FormattedMessage id="DELETE"/>
                    </Button>
                </Modal.Footer>
            </Modal>


            <Modal
                className="cad-modal"
                show={specifyModalVisibility}
                onHide={() => updateSpecifyModalVisibility(false    )}
                size={'md'}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <FormattedMessage id="CAD.LIBRARY.SPECIFY_PART"/>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FormattedMessage id="CAD.LIBRARY.SPECIFY_DETAILS" />
                    <MaterialSelector
                        svgWidth={Number(fileContents[specifyIndex]?.svgWidth.replace('mm', ''))}
                        svgHeight={Number(fileContents[specifyIndex]?.svgHeight.replace('mm', ''))}
                        onChoiceSelect={handleChoiceSelection}
                        choiceData={props.choiceData}
                    />
                    <FormattedMessage id="CAD.LIBRARY.CALC_PRICE_TEXT" />
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant={'secondary'}
                        onClick={() => updateSpecifyModalVisibility(false)}
                    >
                        <FormattedMessage id="CANCEL" />
                    </Button>
                    <Button
                        onClick={() =>
                            specifyFile(fileContents[specifyIndex], fileContents[specifyIndex]?.totalLength, fileContents[specifyIndex]?.totalArea, selectedChoice,)
                        }
                    >
                        <FormattedMessage id="SAVE" />
                    </Button>
                </Modal.Footer>
            </Modal>


            <Draw
                openModal={openModalConfigurator}
                setOpenModal={setOpenModalConfigurator}
                fileId={porcFileId}
                selectedMaterialData={selectedMaterial}
            />


        </>

    );
}

const mapStateToProps = ({ i18n, auth: { user }, draw: { uploadedSvgs, currentSvg } }) => ({
    lang: i18n.lang,
    user,
    uploadedSvgs,
    currentSvg
});

const mapDispatchToProps = {
    updateDrawSVGarray: (uploadedSvgs) =>
        drawActions.updateDrawSVGarray({ SVG: uploadedSvgs }),
    addMaterial: (data, id, svgId) => drawActions.selectMaterial(data, id, svgId),

};

export default injectIntl(
    connect(mapStateToProps, mapDispatchToProps)(FileComponent)
);
